/**
 * DO NOT use tailwind classes as this is used for both RGB and Sava
 */
import clsx from 'clsx';
import Cookies from 'universal-cookie';

import Image from '../../components/basic/Image';
import { LOCALES, LOCALES_LIST } from '../../locales';
import { getFlag } from '../helpers';

const LanguageSelector = ({
  dropDownAlignRight,
  isMiniSite,
  isMobile,
  languages,
  localePaths,
  miniSiteLanguages,
  pageLocale,
  router,
}) => {
  const cookies = new Cookies();

  // we want to prefer the pageLocale (most accurate for the page) if available
  // then the cookied locale if not available, finally the router locale if no cookie present
  const locale =
    pageLocale || cookies.get('NEXT_LOCALE') || router.locale || LOCALES.EN_US;

  const isMiniSitePage = isMiniSite && isMiniSite[0];

  const [lang, activeCountry] = locale.split('-');

  const handleLocaleSelection = (locale) => {
    const country = locale?.split('-')[1];

    cookies.set('NEXT_LOCALE', locale, { path: '/' });
    cookies.set('vercel_country_code', country, { path: '/' });

    // New codepath
    const { path: newPath } = localePaths?.find(({ locale: searchLocale }) => locale === searchLocale) ?? {};
    let fullPath;

    if (newPath) {
      fullPath = `${window.location.origin}${newPath}`;
    } else {
      const newLocale = locale === 'en-US' ? '' : `/${locale}`;
      fullPath = `${window.location.origin}${newLocale}${router.asPath}`;
    }

    window.dataLayer.push({
      event: 'locale_selected',
      locale_selected: locale,
    });

    window.location.replace(fullPath);
  };

  const languagesToUse = isMiniSitePage ? miniSiteLanguages : languages;

  // prevents accidentally leaking locales
  const languagesToShow = languagesToUse.filter(({ _locale }) =>
    LOCALES_LIST.includes(_locale));

  languagesToShow.sort((a) => {
    return a._locale === locale ? -1 : 1;
  });

  const renderFlag = (selectedCountry, selectedLocale) => {
    if (isMiniSitePage && selectedLocale !== 'en-US') {
      return getFlag(selectedCountry, 'language-dropdown__flag-img');
    }

    if (isMiniSitePage && selectedLocale === 'en-US') {
      return (
        <Image
          attributes={{
            className: 'language-dropdown__flag-img mini-site-global-icon',
            mediaURL: 'https://rippling2.imgix.net/global-flag.svg',
            nonResponsive: true,
          }}
        />
      );
    }

    if (!isMiniSitePage) {
      return getFlag(selectedCountry, 'language-dropdown__flag-img');
    }

    return null;
  };

  // @TODO Use one set of HTML for both mobile and desktop
  if (isMobile) {
    return (
      <div>
        <div className="dropdown__wrapper">
          <div className="dropdown__languages">
            <div>
              <div className="language-dropdown__mobile-selectors">
                {/* Locales */}
                <div className="language-dropdown__langs">
                  <h4>Select language</h4>
                  {languagesToShow.map(({ _locale, val }, i) => (
                    <div
                      key={i}
                      className="language-dropdown__lang group"
                      data-active2={_locale === locale || undefined}
                      onClick={() => handleLocaleSelection(_locale)}
                    >
                      <div className="flag-img-container">
                        {renderFlag(_locale.split('-')[1], _locale)}
                      </div>
                      <p>{val}</p>
                      {_locale === locale && (
                        <Image
                          attributes={{
                            className: 'language-dropdown__checkmark',
                            mediaURL:
                              'https://rippling.imgix.net/images/check-filled.svg',
                            nonResponsive: true,
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="language-selector">
      {renderFlag(activeCountry, locale)}
      <p className="language-active-abbrev">{lang?.toUpperCase()}</p>
      <div className={clsx('language-dropdown', dropDownAlignRight ? '-align-right' : '-align-left')}>
        <div>
          <div className="language-dropdown__selectors">
            {/* Locales */}
            <div className="language-dropdown__langs">
              {languagesToShow.map(({ _locale, val }, i) => (
                <div
                  key={i}
                  className="language-dropdown__lang group"
                  data-active2={_locale === locale || undefined}
                  onClick={() => handleLocaleSelection(_locale)}
                >
                  <div key={i} onClick={() => handleLocaleSelection(_locale)}>
                    <div className="flag-img-container">
                      {renderFlag(_locale.split('-')[1], _locale)}
                    </div>
                    <p>{val}</p>
                    {_locale === locale && (
                      <Image
                        attributes={{
                          className: 'language-dropdown__checkmark',
                          mediaURL:
                            'https://rippling.imgix.net/images/check-filled.svg',
                          nonResponsive: true,
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;

const isInternalPage = (url: string) => {
  const regex = /^(https?:\/\/)?(www\.)?rippling\.com(\/.*)?/i;

  return regex.test(url);
};

export const getCleanedUrl = (url: string) => {
  if (!url || url.startsWith('/')) {
    // Nothing to do
    return url;
  }

  if (isInternalPage(url)) {
    const [, path] = url.split('rippling.com', 2);

    return path || '/';
  }

  // External url
  return url;
};

export const getFullPathname = ({ locale, pathname }: {
  locale: string,
  pathname: string
}) => {
  const defaultLocale = 'en-US';
  const localePrefix = locale && locale !== defaultLocale ? `/${locale}` : '';
  const pathWithLocale = `${localePrefix}${pathname}`;

  return pathWithLocale;
};

export function getCurrentFormFillLandingPageUrl() {
  const currentUrl = window.location.href;
  const localhostPattern = /^https?:\/\/localhost:\d+/;

  /**
   * Special case for local testing.
   *
   * If the current URL is localhost, return vanity local-test URL since
   * OP will fail submissions from localhost and cause false alarm alerts.
   */
  if (localhostPattern.test(currentUrl)) {
    return 'https://local-test.rippling.com';
  }

  return currentUrl;
}
